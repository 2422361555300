<template>
  <div class="container mt-4">
    <div class="row">
      <!-- Change Password Form -->
      <div class="col">
        <Card title="Medhop - Reset Password">
          <template v-slot:body>
            <div class="row">
              <div class="col-4">
                <img src="https://is3.cloudhost.id/medhop-prod-bucket/assets/public/images/mobile_security.png" width="400" alt="">
              </div>

              <div class="col-8">
                <b-form @submit.stop.prevent="formOnSubmit">
                  <!-- New Password Input -->
                  <div class="form-group">
                    <label for="newPassword">Password Baru:</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div
                          class="input-group-text"
                          @click="
                            passwordIsVisible == 'password'
                              ? (passwordIsVisible = 'text')
                              : (passwordIsVisible = 'password')
                          "
                        >
                          <div v-if="passwordIsVisible == 'password'">
                            <b-icon-eye-slash></b-icon-eye-slash>
                          </div>
                          <div v-if="passwordIsVisible == 'text'">
                            <b-icon-eye></b-icon-eye>
                          </div>
                        </div>
                      </div>
                      <input
                        :type="passwordIsVisible"
                        class="form-control"
                        placeholder="Password Baru"
                        v-model="form.new_password"
                        id="newPassword"
                      />
                    </div>
                    <small class="text-danger">{{ error.new_password }}</small>
                  </div>

                  <!-- Password Confirmation Input -->
                  <div class="form-group">
                    <label for="passwordConfirmation"
                      >Konfirmasi Password:</label
                    >
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div
                          class="input-group-text"
                          @click="
                            passwordConfirmIsVisible == 'password'
                              ? (passwordConfirmIsVisible = 'text')
                              : (passwordConfirmIsVisible = 'password')
                          "
                        >
                          <div v-if="passwordConfirmIsVisible == 'password'">
                            <b-icon-eye-slash></b-icon-eye-slash>
                          </div>
                          <div v-if="passwordConfirmIsVisible == 'text'">
                            <b-icon-eye></b-icon-eye>
                          </div>
                        </div>
                      </div>
                      <input
                        :type="passwordConfirmIsVisible"
                        class="form-control"
                        placeholder="Ketik Ulang Password"
                        v-model="form.new_password_confirmation"
                        id="passwordConfirmation"
                      />
                    </div>
                    <small class="text-danger">{{
                      error.new_new_password_confirmation
                    }}</small>
                  </div>

                  <!-- Submit & Cancel button -->
                  <b-button type="submit" variant="primary">Simpan</b-button>
                  <!-- <b-button
                class="ml-2"
                variant="default"
                @click="$router.push('/profile/show')"
              >
                Cancel
              </b-button> -->
                </b-form>
              </div>
              
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { getUser } from "@/core/services/jwt.service.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Card,
  },

  data() {
    return {
      // Data
      data: {
        code: this.$route.query.code,
      },
      // Form
      form: {
        old_password: "",
        new_password: "",
        new_password_confirmation: "",
        _method: "put",
      },
      // Error
      error: {
        old_password: "",
        new_password: "",
        new_password_confirmation: "",
      },
      // Other
      oldPasswordIsVisible: "password",
      passwordIsVisible: "password",
      passwordConfirmIsVisible: "password",
    };
  },

  methods: {
    async formOnSubmit() {
      console.log(this.data.code);
      // Make Request
      let response = await module.submit(
        this.form,
        "reset-password/" + this.data.code
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(
          response.success.title,
          response.success.message,
          response.success.icon
        );
        this.$router.push("/login");
      }
    },
    // check code
    async checkCode() {
      console.log(this.data.code);
      // Make Request
      let response = await module.get("get-code/" + this.data.code);
      console.log(response);
      // Check Response
      if (response.status == false) {
        Swal.fire(response.title, response.message, response.icon);
        this.$router.push("/login");
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Reset Password" }]);
    this.checkCode();
  },
};
</script>

<style>
</style>